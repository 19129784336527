<template>
    <main-confirm @confirm="logout" :isQuestion="false" @cancel="$emit('closeModalLogout')" confirm-title="account.logout" :buttonConfirmTitle="btnText" secondTitle="auth.modal_secondary_text" description="auth.are_you_sure_you_want_to_log_out">
        <icon-logout :size="80"/>
    </main-confirm>
</template>

<script>
import MainConfirm from '../../../common/components/modals/MainConfirm.vue';
// import iconLogout from '../../layout/assets/svg/iconLogout.vue';


export default {
    name: "ConfirmLogout",
    components: {
        MainConfirm,
        // iconLogout,
        IconLogout: () => import("@/modules/auth/assets/svg/iconLogout"),
    },
    data() {
        return {
            btnText: 'auth.accept'
        }
    },
    methods:{
        async logout(){
            try {
                this.btnText = 'auth.loading';
                await this.$store.dispatch("auth/logout");
            }catch (e) {
                console.log(e)
            }finally {
                this.btnText= 'auth.accept'
                this.$emit('closeModalLogout');
            }
        }
    }
}
</script>
