<template>
    <div class="container">
        <div class="d-flex justify-content-between align-items-end flex-wrap profile" style="margin: 0% 2%;" >
            <div>
                <h2 class="mb-0 title-18 text-capitalize"> {{ $t("account.hi") }} {{userData.entity.first_name | textFormat}}! </h2>
                <h6 class="mt-1 profile-subtitle" v-if="!mobile">
                    {{$t("account.do_you_need_to_change_your_account")}}?
                    <b class="d-block d-sm-inline main-link weight-500" @click="LogoutModal = true">
                        {{ $t("account.logout") }}
                    </b>
                </h6>
            </div>
            <div class="mt-3 mt-sm-2">
                <h6 class="profile-subtitle">{{$t("account.balance_of_your_account")}}: <b class="balance">{{0 | moneyFormat}}</b></h6>
            </div>
        </div>
        <div class="row">
            <div :key="index" v-for="(tab, index) in options" class="col-6 col-lg-4 col-xl-3" style="margin-top: 5%">
                <card class="profile-card p-3">
                    <router-link class="d-flex flex-md-row flex-column align-items-center align-items-md-start h-100" :to="'account/' + tab.key" :href="tab.key" :target="tab.link ? '_blank' :  ''">
                        <div class="flex-row">
                            <component :is="tab.icon" width="68"/>
                        </div>
                        <div class="flex-column ms-0 ms-md-2 mt-2 mt-md-0">
                            <h6 class="title-15 color-dark-main-navy text-center text-md-start">{{$t(tab.name)}}</h6>
                            <p class="subtitle-13 d-none d-md-block mt-3 mt-sm-0 color-dark-gray-300 mb-0">{{ $t(tab.description) }}.</p> 
                        </div>
                    </router-link>
                </card>
            </div>
        </div>
        <confirm-logout v-if="LogoutModal" @closeModalLogout="LogoutModal = false" />
    </div>
</template>

<script>
import ConfirmLogout from '../../auth/components/ConfirmLogout.vue';
import { mapGetters } from "vuex" 
import { AuthService } from "@/modules/auth/services/auth-service.js";
const authService = new AuthService();
export default {
    name: "Account",
    components: {
        IconOrders: () => import('@/modules/account/assets/svg/iconOrders'),
        IconProfile: () => import('@/modules/account/assets/svg/iconProfile'),
        IconAddresses: () => import('@/modules/account/assets/svg/iconAddresses'),
        IconPaymentMethods: () => import('@/modules/account/assets/svg/iconPaymentMethods'),
        IconWishList: () => import('@/modules/account/assets/svg/iconWishList'),
        IconGiftCards: () => import('@/modules/account/assets/svg/iconGiftCards'),
        IconSettings: () => import('@/modules/account/assets/svg/iconSettings'),
        IconDevicesAndSessions: () => import('@/modules/account/assets/svg/iconDevicesAndSessions'),
        IconTicket: () => import('@/modules/account/assets/svg/iconTicket'),
        Card: () => import('@/common/components/cards/Card'),
        IconInvoice: () => import ('@/modules/account/assets/svg/iconInvoice.vue'),
        ConfirmLogout,
    },
    data() {
        return {
            LogoutModal: false,
            items: [],
            img: '',
            view: '',
            option: [
                {
                    key: "https://help.mundomovil.com.do/en/",
                    name : "account.tickets_description",
                    icon: "IconTicket"
                }
            ],
        };
    },
    computed: {
        ...mapGetters({
            configurations: "common/configurations",
            mobile: 'common/mobile',
            isLogged: "auth/isLogged",
        }),
        userData() {
            return this.$store.getters["auth/userData"];
        },
        options() {
            return [
                {
                    key: "https://help.mundomovil.com.do/en/",
                    name : "account.tickets",
                    description: "account.tickets_description",
                    icon: "IconTicket",
                    link: true
                },
                {
                    key: "profile",
                    name : "account.profile",
                    description: "account.profile_description",
                    icon: "IconProfile",
                    link: false
                },
                {
                    key: "orders",
                    name : "account.orders",
                    description: "account.orders_description",
                    icon: "IconOrders",
                    link: false
                },
                {
                    key: "settings",
                    name : "account.settings",
                    description: "account.settings_description",
                    icon: "IconSettings",
                    link: false
                },
                {
                    key: "devices",
                    name : `account.devices_and_sessions_${this.viewPort('sm') ? 'desktop':'mobile'}`,
                    description: "account.devices_and_sessions_description",
                    icon: "IconDevicesAndSessions"
                },
                {
                    key: "addresses",
                    name : "account.addresses",
                    description: "account.addresses_description",
                    icon: "IconAddresses"
                },
                {
                    key: "payments-methods",
                    name : `account.payment_methods_${this.viewPort('sm') ? 'desktop':'mobile'}`,
                    description: "account.payment_methods_description",
                    icon: "IconPaymentMethods",
                    link: false
                },
                {
                    key: "wishlist",
                    name : "account.wish_list",
                    description: "account.wish_list_description",
                    icon: "IconWishList",
                    link: false
                },
                {
                    key: "infoworks",
                    name: `account.gift_cards_and_rewards_${this.viewPort('sm') ? 'desktop':'mobile'}`,
                    description: "account.gift_cards_and_rewards_description",
                    icon: "IconGiftCards",
                    link: false
                },
                {
                    key: "balance",
                    name : "Balance",
                    description: "account.balance_info",
                    icon: "IconPaymentMethods",
                    link: false
                },
                {
                    key: "quotes",
                    name : "account.quotes",
                    description: "account.verify_info",
                    icon: "IconOrders",
                    link: false
                },
            ];
        },
    },
    methods: {
        show() {
            if(this.userData){
                if (this.userData.customer.price_level_id > 1) {
                    return (this.$store.state["page"].configurations["Metodos de Pago"]["Tarjeta de Credito"][0] && 
                    this.$store.state["page"].configurations["Metodos de Pago"]["Tarjeta de Credito"][1]["Cliente Final"]);
                }
            }else {
                return ( this.$store.state["page"].configurations["Metodos de Pago"]["Tarjeta de Credito"][0] &&
                this.$store.state["page"].configurations["Metodos de Pago"]["Tarjeta de Credito"][1]["Cliente Tienda"]);
            }
        },
        bootIntercom() {
            this.$intercom.boot();
        },
    },
    mounted(){
        if(this.mobile){
            this.$intercom.once('ready', this.bootIntercom);
        }
    },
    async created(){
        if(this.mobile && this.isLogged){
            const user = await authService.login();
            this.$store.commit("auth/SET_USER", user.data.user);
        }
    }
};
</script>

<style scoped>
    @import url('../assets/styles/profile-main.css');
    .profile-card {
        max-width: 380px;
        height: 120px;
    }
    .balance {
        color: #85B561 !important;
        font-weight: 400;
    }
    .profile-card:hover {
        border-color: var(--gray-100) !important;
        box-shadow: none;
    }
    @media (max-width: 575px) {
        .profile-card {
            max-width: 250px !important;
            padding-block: 25px;
            max-width: 200px;
            height: 100%;
        }
        .profile-card h6 {
            font-size: 15px;
            margin-bottom: 0;
        }
    }
</style>
